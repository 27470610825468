import merge from "deepmerge";

import theme from "./theme";

export default merge(theme, {
  colors: {
    text: "#0f2f4e",
    primary: "#0f2f4e",
    secondary: "#2471bf",
  },
});
